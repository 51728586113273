import { Box, Popover } from "@mui/material";

const FilterModal = ({ anchorEl, open, onClose, children }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      sx={{
        marginTop: "20px",
      }}
    >
      <Box>{children}</Box>
    </Popover>
  );
};

export default FilterModal;
