export const getContractStatusStyles = (statusId) => {
  switch (statusId) {
    case 1: // Черновик
      return {
        borderColor: "rgba(158, 158, 158, 0.5)",
        backgroundColor: "rgba(158, 158, 158, 0.1)",
        color: "rgba(66, 66, 66, 0.8)",
      };
    case 2: // На проверке у сотрудника
      return {
        borderColor: "rgba(33, 150, 243, 0.5)",
        backgroundColor: "rgba(33, 150, 243, 0.1)",
        color: "rgba(0, 81, 148, 0.8)",
      };
    case 3: // Контракт на согласовании в банке
      return {
        borderColor: "rgba(0, 121, 191, 0.5)",
        backgroundColor: "rgba(0, 121, 191, 0.1)",
        color: "rgba(0, 51, 102, 0.8)",
      };
    case 4: // Агентский договор на подписании
      return {
        borderColor: "rgba(255, 193, 7, 0.5)",
        backgroundColor: "rgba(255, 193, 7, 0.1)",
        color: "rgba(255, 152, 0, 0.8)",
      };
    case 5: // Агентский договор подписан
      return {
        borderColor: "rgba(76, 175, 80, 0.5)",
        backgroundColor: "rgba(76, 175, 80, 0.1)",
        color: "rgba(56, 142, 60, 0.8)",
      };
    case 6: // Отмена
      return {
        borderColor: "rgba(244, 67, 54, 0.5)",
        backgroundColor: "rgba(244, 67, 54, 0.1)",
        color: "rgba(198, 40, 40, 0.8)",
      };
    case 7: // Контракт согласован
      return {
        borderColor: "rgba(63, 81, 181, 0.5)",
        backgroundColor: "rgba(63, 81, 181, 0.1)",
        color: "rgba(48, 63, 159, 0.8)",
      };
    default:
      return null;
  }
};
