export const options = [
  { placeholder: "Имя", name: "first_name", type: "" },
  { placeholder: "Фамилия", name: "last_name", type: "" },
  { placeholder: "Пароль", name: "password", type: "password" },
  {
    placeholder: "Подтвердите пароль",
    name: "confirm_password",
    type: "password",
  },
  { placeholder: "Email", name: "email", type: "email" },
];
