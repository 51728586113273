import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  authData: {
    refresh: "",
    access: "",
  },
  name: {
    first_name: "",
    last_name: "",
  },
  expirationMessage: "",
  expirationWarnOpen: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setAuthData: (state, action) => {
      state.authData = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setExpirationMessage: (state, action) => {
      state.expirationMessage = action.payload;
    },
    setExpirationWarnOpen: (state, action) => {
      state.expirationWarnOpen = action.payload;
    },
  },
});

export const {
  setIsAuth,
  setAuthData,
  setName,
  setExpirationMessage,
  setExpirationWarnOpen,
} = authSlice.actions;

export default authSlice.reducer;
