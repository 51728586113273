import axios from "axios";
import { baseURL } from "../config.js";

const path = "/agent";

const instance = axios.create({
  baseURL: baseURL + path,
  headers: {},
});

export const agentAPI = {
  async create_agent_profile(authData, data) {
    const {
      full_name,
      address_company,
      mail_address_company,
      inn_company,
      kpp_company,
      okpo_company,
      okato_company,
      ogrn_company,
      okved_company,
      bik_company,
      name_bank,
      check_company,
      korrespondent_company,
      director_company,
      email_company,
      phone_company,
      site_company,
      pay_profile_status,
      work_ilc,
      manager_ilc,
      work_ilc_tp,
      manager_tp,
      comission,
      comment,
      agent_success,
    } = data;

    const { access } = authData;

    try {
      const response = await instance.post(
        `/create_agent_profile/`,
        {
          full_name,
          address_company,
          mail_address_company,
          inn_company,
          kpp_company,
          okpo_company,
          okato_company,
          ogrn_company,
          okved_company,
          bik_company,
          name_bank,
          check_company,
          korrespondent_company,
          director_company,
          email_company,
          phone_company,
          site_company,
          pay_profile_status,
          work_ilc,
          manager_ilc,
          work_ilc_tp,
          manager_tp,
          comission,
          comment,
          agent_success,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error creating profile:",
        err.response ? err.response.data : err
      );
      console.log("Failed to create profile. Check console for details.");
      throw err;
    }
  },

  async get_agent_profiles(data) {
    const { access } = data;

    try {
      const response = await instance.get(`/get_agent_profiles/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting agent profiles:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get agent profiles. Check console for details.");
      throw err;
    }
  },

  async update_agent_profile(data, profile) {
    const { access } = data;

    const {
      full_name,
      address_company,
      mail_address_company,
      inn_company,
      kpp_company,
      okpo_company,
      okato_company,
      ogrn_company,
      okved_company,
      bik_company,
      name_bank,
      check_company,
      korrespondent_company,
      director_company,
      email_company,
      phone_company,
      site_company,
      pay_profile_status,
      work_ilc,
      manager_ilc,
      work_ilc_tp,
      manager_tp,
      comission,
      comment,
      agent_success,
      id,
    } = profile;

    try {
      const response = await instance.put(
        `/update_agent_profile/${id}/`,
        {
          full_name,
          address_company,
          mail_address_company,
          inn_company,
          kpp_company,
          okpo_company,
          okato_company,
          ogrn_company,
          okved_company,
          bik_company,
          name_bank,
          check_company,
          korrespondent_company,
          director_company,
          email_company,
          phone_company,
          site_company,
          pay_profile_status,
          work_ilc,
          manager_ilc,
          work_ilc_tp,
          manager_tp,
          comission,
          comment,
          agent_success,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error updating agent profile:",
        err.response ? err.response.data : err
      );
      console.log("Failed to update agent profile. Check console for details.");
      throw err;
    }
  },

  async get_managers(data) {
    const { access } = data;

    try {
      const response = await instance.get(`/get_managers/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting managers:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get managers. Check console for details.");
      throw err;
    }
  },

  async get_company_managers(data, id) {
    const { access } = data;

    try {
      const response = await instance.get(`/get_company_managers/${id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      return response;
    } catch (err) {
      console.error(
        "Error getting company managers:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get company managers. Check console for details.");
      throw err;
    }
  },

  async add_company_manager(data, managers) {
    const { access } = data;

    try {
      const response = await instance.post(`/add_company_manager/`, managers, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      return response;
    } catch (err) {
      console.error(
        "Error adding company managers:",
        err.response ? err.response.data : err
      );
      console.log("Failed to add company managers. Check console for details.");
      throw err;
    }
  },

  async delete_company_manager(data, managerId) {
    const { access } = data;

    try {
      const response = await instance.delete(
        `/delete_company_manager/${managerId}/`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return response;
    } catch (err) {
      console.error(
        "Error deleting company manager:",
        err.response ? err.response.data : err
      );
      console.log(
        "Failed to delete company manager. Check console for details."
      );
      throw err;
    }
  },

  async change_company_settings(data, companyId, settings) {
    const { access } = data;

    try {
      const response = await instance.post(
        `/change_company_settings/${companyId}/`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return response;
    } catch (err) {
      console.error(
        "Error changing company settings:",
        err.response ? err.response.data : err
      );
      console.log(
        "Failed to change company settings. Check console for details."
      );
      throw err;
    }
  },

  async get_statuses(data) {
    const { access } = data;

    try {
      const response = await instance.get(`/get_statuses/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting statuses:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get statuses. Check console for details.");
      throw err;
    }
  },
};

export default instance;
