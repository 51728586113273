import React from "react";
import styles from "./InfoComponent.module.scss";
import { TextField, Tooltip } from "@mui/material";
import ReusableChip from "../ReusableChip/ReusableChip";
import { FaCloudDownloadAlt } from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import { GrAttachment } from "react-icons/gr";
import DotsSpinner from "../Loaders/DotsSpinner/DotsSpinner";
import { getFileIcon } from "../../services/getFileIcon";
import { getContractStatusStyles } from "../../services/getContractStatusStyles";
import { serverURL } from "../../config";

const InfoComponent = ({
  options,
  text,
  contractStatus,
  company,
  contract,
  handleChangeComment,
  clientComment,
  triggerFileInput,
  handleFileUpload,
  fileLoading,
  contractDocTypes,
  deleteFile,
  fileDeletionStatus,
  otherFiles,
}) => {
  return (
    <div className={styles.body}>
      <h2>{text}</h2>
      {options.map((item, index) => (
        <React.Fragment key={index}>
          <div className={styles.element}>
            <div className={styles.name}>
              <span>{item.label}</span>
            </div>
            {item.type === "Chip" && (
              <div className={styles.field}>
                {item.name === "Status" &&
                  contractStatus &&
                  (() => {
                    const styles = getContractStatusStyles(contractStatus.id);
                    if (!styles) return null;
                    return (
                      <ReusableChip
                        borderColor={styles.borderColor}
                        backgroundColor={styles.backgroundColor}
                        color={styles.color}
                        label={contractStatus.status_title}
                        minHeight="45px"
                        minWidth="100px"
                      />
                    );
                  })()}
                {item.name === "is_vbk" && (
                  <ReusableChip
                    borderColor={
                      contract.is_vbk
                        ? "rgba(76, 175, 80, 0.5)"
                        : "rgba(33, 150, 243, 0.5)"
                    }
                    backgroundColor={
                      contract.is_vbk
                        ? "rgba(76, 175, 80, 0.1)"
                        : "rgba(33, 150, 243, 0.1)"
                    }
                    color={
                      contract.is_vbk
                        ? "rgba(28, 107, 31, 0.8)"
                        : "rgba(0, 81, 148, 0.8)"
                    }
                    label={contract.is_vbk ? "Да" : "Нет"}
                    minHeight="45px"
                    minWidth="100px"
                  />
                )}
              </div>
            )}
            {item.name === "TextCompany" && (
              <div className={styles.field}>{company.full_name}</div>
            )}
            {item.name === "TextManagerComment" && (
              <div className={styles.field}>{contract.comment || "---"}</div>
            )}
            {item.name === "TextContractNumber" && (
              <div className={styles.field}>{contract.contract_number}</div>
            )}
            {item.type === "TextField" && (
              <div className={styles.field}>
                <TextField
                  label={item.text}
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root": {
                      color: "#757575",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#757575",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#757575",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#757575",
                    },
                  }}
                  value={clientComment}
                  // InputLabelProps={{
                  //   shrink: !!contract.comment_client,
                  // }}
                  onChange={handleChangeComment}
                  disabled={
                    (contractStatus?.id === 5 ||
                      contractStatus?.id === 6 ||
                      contractStatus?.id === 7) &&
                    true
                  }
                />
              </div>
            )}
          </div>
          <div className={styles.line} />
        </React.Fragment>
      ))}
      <React.Fragment>
        {contract.files?.length > 0 ? (
          <>
            {contract.files.map(
              (item, index) =>
                item.doc_type !== 11 && (
                  <React.Fragment key={index}>
                    <div className={styles.element}>
                      <div className={styles.name}>
                        <span>
                          {contractDocTypes.find(
                            (type) => type.id === item.doc_type
                          )?.type_title || "Файлы"}
                        </span>
                      </div>
                      <div className={styles.filesContainer}>
                        <FilesCard
                          getFileIcon={getFileIcon}
                          item={item}
                          deleteFile={deleteFile}
                          fileDeletionStatus={fileDeletionStatus}
                        />
                      </div>
                    </div>
                    <div className={styles.line} />
                  </React.Fragment>
                )
            )}
            {otherFiles?.length > 0 && (
              <>
                <div className={styles.element}>
                  <div className={styles.name}>
                    <span>Другое</span>
                  </div>
                  <div className={styles.filesContainer}>
                    {otherFiles?.map((item, index) => (
                      <React.Fragment key={index}>
                        <FilesCard
                          item={item}
                          getFileIcon={getFileIcon}
                          deleteFile={deleteFile}
                          fileDeletionStatus={fileDeletionStatus}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className={styles.line} />
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.element}>
              <div className={styles.name}>
                <span>Файлы</span>
              </div>
              <div className={styles.filesContainer}>
                Нет загруженных файлов
              </div>
            </div>
            <div className={styles.line} />
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Другие файлы</span>
          </div>
          <div className={styles.field}>
            <button
              className={` ${
                contractStatus?.id === 5 ||
                contractStatus?.id === 6 ||
                contractStatus?.id === 7
                  ? styles.buttonDisabled
                  : styles.uploadButton
              }`}
              onClick={() => triggerFileInput("OtherFiles")}
              disabled={
                (contractStatus?.id === 5 ||
                  contractStatus?.id === 6 ||
                  contractStatus?.id === 7) &&
                true
              }
            >
              {fileLoading ? (
                <DotsSpinner />
              ) : (
                <>
                  <span>Выберите другие файлы</span>
                  <GrAttachment size={20} />
                </>
              )}
            </button>
            <input
              id="OtherFiles"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

const FilesCard = ({ getFileIcon, item, deleteFile, fileDeletionStatus }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.icon}>{getFileIcon(item.file_type)}</div>
        <span className={styles.text}>{item.file_name}</span>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.downloadButton}>
          <a
            href={`${serverURL}${item.contr_file}`}
            download={item.file_name}
            target={
              [
                "application/pdf",
                "image/jpeg",
                "image/png",
                "image/svg+xml",
                "image/heic",
                "image/heif",
              ].includes(item.file_type)
                ? "_blank"
                : "_self"
            }
            rel="noreferrer"
            className={styles.downloadLink}
          >
            <FaCloudDownloadAlt size={20} />
          </a>
        </button>
        <button
          className={styles.deleteButton}
          onClick={() => deleteFile(item.id, item.doc_type)}
        >
          {fileDeletionStatus[item.doc_type] ? (
            <DotsSpinner />
          ) : (
            <Tooltip title="Удалить файл" className={styles.downloadLink}>
              <DeleteIcon className={styles.icon} />
            </Tooltip>
          )}
        </button>
      </div>
    </div>
  );
};

export default InfoComponent;
