import axios from "axios";
import { baseURL } from "../config.js";

const path = "/users";

const instance = axios.create({
  baseURL: baseURL + path,
  headers: {},
});

export const authAPI = {
  async login(email, password) {
    try {
      const response = await instance.post(`/token/`, {
        email,
        password,
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error logging in:",
        err.response ? err.response.data : err
      );
      console.log("Failed to login. Check console for details.");
      throw err;
    }
  },
  async refreshToken(authData) {
    const { refresh } = authData;

    console.log("refresh", refresh);

    try {
      const response = await instance.post(`/token/refresh/`, { refresh });
      console.log("response", response);
      return response;
    } catch (err) {
      console.error(
        "Error refreshing access token:",
        err.response ? err.response.data : err
      );
      console.log("Failed to refresh token. Check console for details.");
      throw err;
    }
  },

  async register(data) {
    const { first_name, last_name, password, email, phone_number } = data;
    try {
      const response = await instance.post(`/registration/`, {
        first_name,
        last_name,
        password,
        email,
        phone_number,
      });
      return response;
    } catch (err) {
      console.error(
        "Error registering:",
        err.response ? err.response.data : err
      );
      console.log("Failed to register. Check console for details.");
      throw err;
    }
  },

  async logout(data) {
    const { refresh, access } = data;
    try {
      const response = await instance.post(
        `/logout/`,
        {
          refresh_token: refresh,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("data", response);
      return response;
    } catch (err) {
      console.error(
        "Error logging out:",
        err.response ? err.response.data : err
      );
      console.log("Failed to log out. Check console for details.");
      throw err;
    }
  },

  async send_email(email) {
    try {
      const response = await instance.post(`/password_reset/`, {
        email,
      });
      console.log("data", response);
      return response;
    } catch (err) {
      console.error(
        "Error sending email:",
        err.response ? err.response.data : err
      );
      console.log("Failed to send email. Check console for details.");
      throw err;
    }
  },

  async reset_password(data) {
    const { password, token } = data;
    try {
      const response = await instance.post(`/password_reset/confirm/`, {
        password,
        token,
      });
      console.log("data", response);
      return response;
    } catch (err) {
      console.error(
        "Error resetting password:",
        err.response ? err.response.data : err
      );
      console.log("Failed to reset password. Check console for details.");
      throw err;
    }
  },
};
