import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requests: [],
  // request: "",
  requestStatuses: [],
  currencies: [],
  requestDocTypes: [],
};

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    removeRequests: (state, action) => {
      state.requests = [];
    },
    // setRequest: (state, action) => {
    //   state.request = action.payload;
    // },
    // removeRequest: (state, action) => {
    //   state.request = "";
    // },
    addRequests: (state, action) => {
      state.requests = [...state.requests, action.payload];
    },
    updateRequests: (state, action) => {
      state.requests = state.requests.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    setRequestsStatuses: (state, action) => {
      state.requestStatuses = action.payload;
    },
    removeRequestsStatuses: (state, action) => {
      state.requestStatuses = null;
    },
    setRequestDocTypes: (state, action) => {
      state.requestDocTypes = action.payload;
    },
  },
});

export const {
  setRequests,
  setCurrencies,
  addRequests,
  setRequestsStatuses,
  updateRequests,
  removeRequests,
  removeRequestsStatuses,
  setRequestDocTypes,
} = requestSlice.actions;

export default requestSlice.reducer;
