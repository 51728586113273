import { Box, Modal } from "@mui/material";
import styles from "./MainModal.module.scss";

const MainModal = ({ open, onClose, children, isContract }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        className={styles.container}
        data-is-contract={isContract ? "true" : "false"}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default MainModal;
