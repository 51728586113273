import styles from "./App.module.scss";
import LoginPage from "./Pages/LoginPage/LoginPage";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import HomePage from "./Pages/HomePage/HomePage";
import PrivateRoute from "./Routes/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import ContractPage from "./Pages/ContractPage/ContractPage";
import RequestsPage from "./Pages/RequestsPage/RequestsPage";
import { useMediaQuery } from "react-responsive";
import NavBar from "./components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Cookies from "js-cookie";
import {
  setAuthData,
  setExpirationMessage,
  setExpirationWarnOpen,
  setIsAuth,
} from "./redux/slices/authSlice";
import EmailForResetPage from "./Pages/EmailForResetPage/EmailForResetPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage/ResetPasswordPage";
import { agentAPI } from "./api/agentAPI";
import {
  setCompany,
  setCompanyId,
  setCompanyManagers,
  setProfiles,
  setProfileStatus,
  setProfileStatuses,
} from "./redux/slices/agentSlice";
import ModalsContext from "./contexts/ModalsContext";
import {
  setContract,
  setContractDocTypes,
  setContractId,
  setContracts,
  setContractStatus,
  setContractStatuses,
} from "./redux/slices/contractSlice";
import { contractAPI } from "./api/contractAPI";
import { authAPI } from "./api/authAPI";
import SnackbarWarning from "./reusables/SnackbarWarning";
import AllRequestsPage from "./Pages/AllRequestsPage/AllRequestsPage";

function App() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const [clientComment, setClientComment] = useState("");

  const [loading, setLoading] = useState(false);

  const [checkUpdate, setCheckUpdate] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [managersUpdate, setManagersUpdate] = useState({
    manager_1: null,
    manager_2: null,
  });

  const [managers_ilc_tp, setManagers_ilc_tp] = useState([]);
  const [managers_ilc, setManagers_ilc] = useState([]);

  const expirationMessage = useSelector(
    (state) => state.auth.expirationMessage
  );
  const expirationWarnOpen = useSelector(
    (state) => state.auth.expirationWarnOpen
  );

  const authData = useSelector((state) => state.auth.authData);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const companyId = useSelector((state) => state.agent.companyId);
  const contractId = useSelector((state) => state.contract.contractId);

  const {
    handleProfileModalOpen,
    handleContractModalOpen,
    handleContractModalClose,
    newContractModalOpen,
    setNavbarDisabled,
  } = useContext(ModalsContext);

  const tabletMediaQuery = useMediaQuery({ maxWidth: 800 });
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    dispatch(setExpirationWarnOpen(false));
  };

  const getAuthData = () => {
    const authDataCookie = Cookies.get("authData");
    let authData = null;
    if (authDataCookie) {
      try {
        authData = JSON.parse(authDataCookie);
      } catch (error) {
        console.error("Error parsing authData cookie:", error);
      }
    }
    return authData;
  };

  const updateProfileStatusAndManagers = (companyData, statuses) => {
    const foundStatus = statuses.find(
      (status) => status.id === companyData.pay_profile_status
    );
    if (foundStatus) {
      dispatch(setProfileStatus(foundStatus));
    }
    setCheckUpdate({
      firstCheck: companyData.work_ilc,
      secondCheck: companyData.work_ilc_tp,
    });
    setManagersUpdate({
      manager_1: companyData.manager_ilc,
      manager_2: companyData.manager_tp,
    });
  };

  const updateContractStatusAndComment = (contractData, statuses) => {
    const foundStatus = statuses.find(
      (status) => status.id === contractData.contract_status
    );
    if (foundStatus) {
      dispatch(setContractStatus(foundStatus));
    }
    setClientComment(contractData.comment_client);
  };

  useEffect(() => {
    const currentAuthData = getAuthData();
    const currentPath = location.pathname;

    if (!currentAuthData && currentPath !== "/reset-password/confirm") {
      if (isFirstRender) {
        navigate("/auth");
      }
    } else if (currentAuthData && !isAuth) {
      dispatch(setAuthData(currentAuthData));
      dispatch(setIsAuth(true));
    }
    setIsFirstRender(false);
    setAuthChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchParams] = useSearchParams();
  const agent_id = searchParams.get("agent_id");
  const contract_id = searchParams.get("contract_id");

  useEffect(() => {
    if (agent_id) {
      dispatch(setCompanyId(parseInt(agent_id)));
    }
  }, [agent_id, dispatch]);

  useEffect(() => {
    if (contract_id) {
      dispatch(setContractId(parseInt(contract_id)));
    }
  }, [contract_id, dispatch]);

  useEffect(() => {
    if (isAuth) {
      const fetchProfilesAndContracts = async (newAuthData = null) => {
        console.log("working!");

        try {
          setLoading(true);
          setNavbarDisabled(true);
          let profile;
          const response = await agentAPI.get_agent_profiles(
            newAuthData || authData
          );

          if (response.statusText === "OK") {
            const profilesData = response.data;
            if (profilesData.length === 0) {
              handleProfileModalOpen();
            }
            dispatch(setProfiles(profilesData));

            const dataManagers = await agentAPI.get_managers(
              newAuthData || authData
            );

            if (dataManagers.statusText === "OK") {
              const managerIlc = dataManagers.data.filter(
                (item) => item.company === 1
              );
              const managerIlcTp = dataManagers.data.filter(
                (item) => item.company === 2
              );
              setManagers_ilc([
                ...managerIlc,
                {
                  id: null,
                  first_name: "Не назначен",
                  last_name: "Не назначен",
                  full_name: "Не назначен",
                  id_hub: null,
                  user: null,
                  company: 1,
                },
              ]);
              setManagers_ilc_tp([
                ...managerIlcTp,
                {
                  id: null,
                  first_name: "Не назначен",
                  last_name: "Не назначен",
                  full_name: "Не назначен",
                  id_hub: null,
                  user: null,
                  company: 2,
                },
              ]);
            }

            const dataStatuses = await agentAPI.get_statuses(
              newAuthData || authData
            );

            if (dataStatuses.statusText === "OK") {
              dispatch(setProfileStatuses(dataStatuses.data));
              if (profilesData.length > 0) {
                if (companyId) {
                  const selectedProfile = profilesData.filter(
                    (item) => item.id === companyId
                  )[0];
                  if (selectedProfile) {
                    profile = selectedProfile;
                    dispatch(setCompany(selectedProfile));
                    updateProfileStatusAndManagers(
                      selectedProfile,
                      dataStatuses.data
                    );
                  } else {
                    const selectedCompany = profilesData[0];
                    profile = selectedCompany;
                    dispatch(setCompany(selectedCompany));
                    updateProfileStatusAndManagers(
                      selectedCompany,
                      dataStatuses.data
                    );
                  }
                } else {
                  const selectedCompany = profilesData[0];
                  profile = selectedCompany;
                  dispatch(setCompany(selectedCompany));
                  updateProfileStatusAndManagers(
                    selectedCompany,
                    dataStatuses.data
                  );
                }
              }
            }

            const companyManagers = await agentAPI.get_company_managers(
              newAuthData || authData,
              profile.id
            );

            if (companyManagers.statusText === "OK") {
              console.log("companyManagers", companyManagers.data);
              dispatch(setCompanyManagers(companyManagers.data));
            }
          }
          if (profile) {
            const responseContract = await contractAPI.get_contracts(
              newAuthData || authData,
              profile.id
            );

            if (responseContract.statusText === "OK") {
              const contractsData = responseContract.data;
              dispatch(setContracts(contractsData));

              if (contractsData.length === 0) {
                handleContractModalOpen();
              } else if (contractsData.length > 0 && newContractModalOpen) {
                handleContractModalClose();
              }

              const dataStatuses = await contractAPI.get__contract_statuses(
                newAuthData || authData
              );

              if (dataStatuses.statusText === "OK") {
                dispatch(setContractStatuses(dataStatuses.data));

                if (contractsData.length > 0) {
                  if (contractId) {
                    const selectedContract = contractsData.filter(
                      (item) => item.id === contractId
                    )[0];

                    if (selectedContract) {
                      dispatch(setContract(selectedContract));
                      updateContractStatusAndComment(
                        selectedContract,
                        dataStatuses.data
                      );
                    } else {
                      const selectedContract = contractsData[0];
                      dispatch(setContract(selectedContract));
                      updateContractStatusAndComment(
                        selectedContract,
                        dataStatuses.data
                      );
                    }
                  } else {
                    const selectedContract = contractsData[0];
                    dispatch(setContract(selectedContract));
                    updateContractStatusAndComment(
                      selectedContract,
                      dataStatuses.data
                    );
                  }
                }
              }
              const contractDocTypes = await contractAPI.get_contract_doc_types(
                newAuthData || authData
              );
              if (contractDocTypes.statusText === "OK") {
                dispatch(setContractDocTypes(contractDocTypes.data));
              }
            }
          }
          setLoading(false);
          setNavbarDisabled(false);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            try {
              const newAuthData = await authAPI.refreshToken(authData);
              if (newAuthData && newAuthData.data.access) {
                const updatedAuthData = newAuthData.data;
                Cookies.set("authData", JSON.stringify(updatedAuthData), {
                  secure: true,
                });
                dispatch(setAuthData(updatedAuthData));
                fetchProfilesAndContracts(updatedAuthData);
              }
            } catch (refreshError) {
              if (
                refreshError &&
                (refreshError.response.status === 401 ||
                  refreshError.response.status === 400)
              ) {
                console.log("Error refreshing token:", refreshError);
                Cookies.remove("authData");
                dispatch(setIsAuth(false));
                dispatch(setExpirationWarnOpen(true));
                dispatch(
                  setExpirationMessage(
                    "Текущая сессия истекла. Пожалуйста, авторизуйтесь заново"
                  )
                );
                setLoading(false);
                setNavbarDisabled(false);
              }
            }
          } else {
            console.log(err);
            setLoading(false);
            setNavbarDisabled(false);
          }
        }
      };
      fetchProfilesAndContracts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, companyId, contractId, dispatch]);

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.app}>
      {isAuth && <Header />}

      <div className={styles.main}>
        <SnackbarWarning
          handleClose={handleClose}
          snackbarText={expirationMessage}
          snackbarOpen={expirationWarnOpen}
        />
        {isAuth && !tabletMediaQuery && <Sidebar />}
        {isAuth && tabletMediaQuery && <NavBar />}
        <ScrollToTop />
        <div className={styles.content}>
          <Routes>
            <Route path="/auth" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/reset-password" element={<EmailForResetPage />} />
            <Route
              path="/reset-password/confirm"
              element={<ResetPasswordPage />}
            />

            <Route
              path="/"
              element={
                <PrivateRoute
                  element={
                    <HomePage
                      updateProfileStatusAndManagers={
                        updateProfileStatusAndManagers
                      }
                      checkUpdate={checkUpdate}
                      managersUpdate={managersUpdate}
                      setCheckUpdate={setCheckUpdate}
                      setManagersUpdate={setManagersUpdate}
                      loading={loading}
                      setLoading={setLoading}
                      managers_ilc_tp={managers_ilc_tp}
                      managers_ilc={managers_ilc}
                    />
                  }
                />
              }
            />
            <Route
              path="/contracts"
              element={
                <PrivateRoute
                  element={
                    <ContractPage
                      updateContractStatusAndComment={
                        updateContractStatusAndComment
                      }
                      clientComment={clientComment}
                      setClientComment={setClientComment}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  }
                />
              }
            />
            <Route
              path="/requests"
              element={<PrivateRoute element={<RequestsPage />} />}
            />
            <Route
              path="/all-requests"
              element={<PrivateRoute element={<AllRequestsPage />} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
