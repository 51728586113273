import axios from "axios";
import { baseURL } from "../config.js";

const path = "/agent_contracts";

const instance = axios.create({
  baseURL: baseURL + path,
  headers: {},
});

export const contractAPI = {
  async create_contracts(authData, data) {
    const {
      contract_number,
      contract_status,
      comment,
      comment_client,
      agent,
      files,
      is_vbk,
    } = data;
    const { access } = authData;

    try {
      const response = await instance.post(
        `/create_contracts/`,
        {
          contract_number,
          contract_status,
          comment,
          comment_client,
          agent,
          files,
          is_vbk,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error creating contract:",
        err.response ? err.response.data : err
      );
      console.log("Failed to create contract. Check console for details.");
      throw err;
    }
  },

  async get_contract_doc_types(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get__contract_doc_types/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting contract doc types:",
        err.response ? err.response.data : err
      );
      console.log(
        "Failed to get contract doc types. Check console for details."
      );
      throw err;
    }
  },

  async get_contracts(authData, id = null) {
    const { access } = authData;

    const url = id ? `/get_contracts/${id}/` : `/get_contracts/`;

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting contracts:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get contracts. Check console for details.");
      throw err;
    }
  },

  async get__contract_statuses(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get__contract_statuses/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting contract statuses:",
        err.response ? err.response.data : err
      );
      console.log(
        "Failed to get contract statuses. Check console for details."
      );
      throw err;
    }
  },

  async update__contract(authData, comment_client, contract_status, id) {
    const { access } = authData;

    try {
      const response = await instance.put(
        `/update__contract/${id}/`,
        {
          comment_client,
          contract_status,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error updating contract:",
        err.response ? err.response.data : err
      );
      console.log("Failed to update contract. Check console for details.");
      throw err;
    }
  },
  async postfile(authData, formData) {
    const { access } = authData;

    try {
      const response = await instance.post(`/postfile/`, formData, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error posting file:",
        err.response ? err.response.data : err
      );
      console.log("Failed to post file. Check console for details.");
      throw err;
    }
  },

  async delete_contr_file(authData, file_id) {
    const { access } = authData;

    try {
      const response = await instance.delete(`/delete_contr_file/${file_id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error deleting contract file:",
        err.response ? err.response.data : err
      );
      console.log("Failed to delete contract file. Check console for details.");
      throw err;
    }
  },
};
