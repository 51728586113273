export const requiredFields = [
  "full_name",
  "address_company",
  "mail_address_company",
  "inn_company",
  "kpp_company",
  "okpo_company",
  "okato_company",
  "ogrn_company",
  "okved_company",
  "bik_company",
  "name_bank",
  "check_company",
  "korrespondent_company",
  "director_company",
  "email_company",
  "phone_company",
];

export const initialFormData = {
  full_name: "",
  address_company: "",
  mail_address_company: "",
  inn_company: "",
  kpp_company: "",
  okpo_company: "",
  okato_company: "",
  ogrn_company: "",
  okved_company: "",
  bik_company: "",
  name_bank: "",
  check_company: "",
  korrespondent_company: "",
  director_company: "",
  email_company: "",
  phone_company: "",
  site_company: "",
  comission: 5,
  comment: "",
};
